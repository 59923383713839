
<i18n locale="th" lang="yaml" >
part.section.general : "ข้อมูลทั่วไป"
</i18n>

<template>
	<div class="page-padding">
		<h2 class="page-section-title">{{$t('part.section.general')}}</h2>
	</div>
</template>

<script>
export default {
	props : {
		part : {
			type: Object ,
			default : () => []
		}
	}
}
</script>
